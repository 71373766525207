"use strict";

class StickyObserver {
	/**
	 * Creates an instance of StickyObserver
	 *
	 * @param   {HTMLElement}  element     Element to observe
	 *
	 * @return  {StickyObserver}           Instance of StickyObserver
	 */
	constructor(elementToObserve, elementToAffect, params = {}) {
		if (!elementToObserve || !elementToAffect) {
			console.error(
				"%c Couldn't instantiate StickyObserver. 'elementToObserve' or 'elementToAffect' doesn't exist.",
				"color: red"
			);
			return;
		}

		this.target = elementToObserve;
		this.element = elementToAffect;
		this.defaults = {};
		this.options = { ...this.defaults, ...params };

		this.observer = new IntersectionObserver(
			(entries) => {
				entries.forEach(({ intersectionRatio }) => {
					this.element.classList.toggle("stickied", intersectionRatio < 1);
				});
			},
			{
				rootMargin: `-${parseInt(
					window.getComputedStyle(this.element).top
				)}px`,
				threshold: [0, 1.0],
			}
		);

		this.observer.observe(this.target);
	}
}
